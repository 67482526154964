/* -----------------------------------------------------------------------------
  x_template_sidemenu_addon
*/

:root {
  --x-side-nav-expanded-width: 11rem;
  --x-side-nav-width: 3.5rem;
  --x-side-menu-margin-expanded: 0.5rem;
  --x-side-nav-transition-time: 0.3s;
  --x-side-nav-background-color: #fff;
  --x-side-nav-medium-size-expanded: false;
}

.main-content {
  margin-left: var(--x-side-nav-expanded-width);
  transition: var(--x-side-nav-transition-time);
}

.footer-side-nav {
  right: 0;
  position: fixed;
  bottom: 0;
  z-index: 8030;
}

.sidenav {
  height: 100%;
  -webkit-transition: var(--x-side-nav-transition-time);
  transition: var(--x-side-nav-transition-time);
  position: fixed;
  z-index: 8031;
  top: 0;
  left: 0;
  background-color: var(--x-side-nav-background-color);
  box-shadow: 0 0.625rem 0.938rem -0.188rem rgba(0, 0, 0, 0.2), 0 0.5rem 0.625rem -0.375rem rgba(0, 0, 0, 0.1);
  width: var(--x-side-nav-expanded-width);

  #expand-sidenav {
    opacity: 0;
    pointer-events: none;
  }

  ul {
    margin: 0;

    .colored-header-spacer {
      height: 0.875rem;
      margin-bottom: 0.875rem;
    }

    li {
      .dropdown-toggle::after {
        display: none;
      }

      .sidenav-link-text {
        display: block;
      }

      .dropdown-menu-sidenav {

        /* stylelint-disable declaration-no-important */
        transform: translate3d(0, 0, 0) !important;
        left: 100% !important;

        /* stylelint-enable declaration-no-important */
        z-index: 9999;
        margin: 0;
        position: relative;
        box-shadow: 0 0.625rem 0.938rem -0.188rem rgba(0, 0, 0, 0.2);
        border: var(--x-dropdown-border-width) solid var(--x-dropdown-border-color);
        border-radius: var(--x-dropdown-border-radius);
        padding: 0.3rem;
        background-clip: padding-box;
        background-color: var(--x-dropdown-bg-color);
        color: var(--x-dropdown-color);
        float: left;
        list-style: none;
        min-width: 10rem;
        text-align: left;
        top: 100%;
      }

      .side-nav-link {
        color: var(--x-navbar-color);
        fill: var(--x-navbar-color);
        text-transform: uppercase;

        &:hover {
          color: var(--x-navbar-hover-color);
          fill: var(--x-navbar-hover-color);
        }

        &:focus {
          color: var(--x-navbar-hover-color);
          fill: var(--x-navbar-hover-color);
        }
      }
    }
  }

  .dropdown-menu-sidenav {
    .show {
      display: block;
    }
  }

  #expand-sidenav[aria-expanded="true"] {
    color: var(--x-navbar-hover-color);
    fill: var(--x-navbar-hover-color);
  }

  #expand-sidenav[aria-expanded="false"] {
    color: var(--x-navbar-color);
    fill: var(--x-navbar-color);
  }

  .side-nav-link {
    align-items: center;
    width: min-content;
  }
}

.main-block {
  transition: margin-left var(--x-side-nav-transition-time);
}

.navbar-toggler {
  background: none;
  border: 0;
  padding: 0.25rem 0;
}

/* -----------------------------------------------------------------------------
  Medium large devices
*/

@media only screen and (max-width: 75rem) {
  @if (var(--x-side-nav-medium-size-expanded)) {
    .main-content {
      margin-left: var(--x-side-nav-expanded-width);
    }

    .sidenav {
      width: var(--x-side-nav-expanded-width);

      .sidenav-link-text {
        display: block;
      }
    }
  } @else {
    .sidenav-link-text[aria-expanded="false"] {

      /* stylelint-disable declaration-no-important */
      display: none !important;

      /* stylelint-enable declaration-no-important */
    }

    .main-content {
      margin-left: var(--x-side-nav-width);
    }

    .sidenav {
      width: var(--x-side-nav-width);

      .sidenav-link-text {
        display: none;
      }
    }
  }
}

/* -----------------------------------------------------------------------------
  Small devices
*/

@media only screen and (max-width: 61.938rem) {
  .sidenav {
    width: 0;

    ul {
      li {
        &[sidemenu-expanded="false"] {
          margin-left: auto;
          margin-right: auto;
        }

        &[sidemenu-expanded="true"] {
          margin-left: var(--x-side-menu-margin-expanded);
        }
      }
    }

    #expand-sidenav {
      pointer-events: auto;
      opacity: 1;
      display: flex;
      align-items: center;
    }

    .sidenav-link-text[aria-expanded="true"] {
      display: block;
    }

    .sidenav-link-text[aria-expanded="false"] {
      display: none;
    }
  }

  .sidenav[aria-expanded="true"] {
    width: var(--x-side-nav-width);
  }

  .sidenav[sidemenu-expanded="true"] {
    width: var(--x-side-nav-expanded-width);
  }

  .sidenav[sidemenu-expanded="false"] {
    width: var(--x-side-nav-width);
  }

  .sidenav[aria-expanded="false"] {
    width: 0;
  }

  .main-content {
    margin-left: 0;
  }
}
